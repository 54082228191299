import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '@mazboletos/selling-point/auth/data-access';
import { RouteUtils } from '@mazboletos/shared/utils/route';
import { from, map } from 'rxjs';

export const isNotAuthenticatedGuard: CanActivateFn = () => {
  const auth = inject(AuthService);
  const router = inject(Router);

  return from(auth.current()).pipe(
    map((user) => {
      if (user) {
        router.navigate([RouteUtils.Dashboard, RouteUtils.Events]);
      }
      return true;
    })
  );
};
