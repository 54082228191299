import { Injectable, NgModule } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, RouterModule, UrlTree } from '@angular/router';
import { AuthDataAccessModule, AuthService } from '@mazboletos/selling-point/auth/data-access';
import { LoadingDialogComponent, LoadingDialogComponentModule } from '@mazboletos/shared/ui/dialog/loading-dialog';
import { RouteUtils } from '@mazboletos/shared/utils/route';
import { Observable, from, map } from 'rxjs';

@Injectable()
export class IsAuthenticatedGuard {
  constructor(private auth: AuthService, private router: Router, private dialog: MatDialog) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.dialog.open(LoadingDialogComponent);
    return from(this.auth.current()).pipe(
      map((user) => {
        this.dialog.closeAll();
        if (user) {
          return true;
        }
        return this.router.parseUrl(`${RouteUtils.SignIn}`);
      })
    );
  }
}

@NgModule({
  imports: [AuthDataAccessModule, RouterModule, LoadingDialogComponentModule],
  providers: [IsAuthenticatedGuard],
})
export class IsAuthenticatedGuardModule {}
