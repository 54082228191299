import { NgIf } from '@angular/common';
import { Directive } from '@angular/core';

@Directive({
  selector: '[mazIfHasRole]',
  standalone: true,
  hostDirectives: [
    {
      directive: NgIf,
      inputs: ['ngIfElse: isHasRoleElse'],
    },
  ],
})
export class IfHasRoleDirective {}
