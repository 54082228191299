import { CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { AuthService } from '@mazboletos/selling-point/auth/data-access';
import { from, map } from 'rxjs';

export function hasRoleGuard(roles: string[]): CanActivateFn {
  return () => {
    const auth = inject(AuthService);

    return from(auth.current()).pipe(
      map((user) => {
        if (user) {
          const localUser = JSON.parse(localStorage.getItem('_user') || '{}');
          if (!localUser.rolesMap) {
            location.reload();
          }
          for (const role of roles) {
            if (localUser.rolesMap[role]) {
              return true;
            }
          }
        }
        return false;
      })
    );
  };
}
