import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { AuthService } from '@mazboletos/selling-point/auth/data-access';
import { from, map } from 'rxjs';
import { RouteUtils } from '@mazboletos/shared/utils/route';

export const permissionsGuard: CanActivateFn = (route) => {
  const auth = inject(AuthService);
  const router = inject(Router);

  return from(auth.current()).pipe(
    map((user) => {
      const destinationRoute = route.url[0].path;
      if (user) {
        const localUser = JSON.parse(localStorage.getItem('_user') || '{}');
        if (!localUser.rolesMap) {
          location.reload();
        }
        if (destinationRoute !== RouteUtils.Reports && localUser.rolesMap['Reporter'] && localUser.roles.length === 1) {
          router.navigate([RouteUtils.Dashboard, RouteUtils.Reports]);
        } else if (
          destinationRoute === RouteUtils.Reports &&
          localUser.rolesMap['Selling Point'] &&
          localUser.roles.length === 1
        ) {
          router.navigate([RouteUtils.Dashboard, RouteUtils.Events]);
        }
      }
      return true;
    })
  );
};
